const tannerGray = {
  25: '#FBFBFB',
  50: '#F8F8F8',
  100: '#F1F1F1',
  200: '#E1E1E1',
  300: '#C4C4C4',
  400: '#ABABAE',
  500: '#8E8E8E',
  600: '#707070',
  700: '#525252',
  800: '#363636',
  900: '#212121',
};

const tannerPurple = {
  50: '#EBE3FA',
  100: '#D7C6F5',
  200: '#C3AAF0',
  300: '#AF8EEB',
  400: '#8D5EE2',
  500: '#7942DD',
  600: '#6738BC',
  700: '#552E9B',
  800: '#43247A',
  900: '#301A58',
};

const tannerBlue = {
  50: '#ECF4FB',
  100: '#A2CDF2',
  200: '#1194F6',
  300: '#0F84DC',
  500: '#0060E0',
};

const tannerSemantic = {
  success: {
    color: '#07702A',
    background: '#D5F0E7',
    100: '#D5F0E7',
    200: '#A2DDBE',
    300: '#6DC591',
    600: '#00511B',
    700: '#003512',
  },
  info: {
    color: tannerBlue['500'],
    background: '#DEEBFC',
    100: '#DEEBFC',
    200: '#BCD5F8',
    300: '#668CEC',
    600: '#0049AB',
    700: '#003686',
  },
  warning: {
    color: '#B1480B',
    background: '#FDF4DA',
    100: '#FFF4D9',
    200: '#FCE0BC',
    300: '#F9C698',
    600: '#903500',
    700: '#672600',
  },
  error: {
    color: '#D10A12',
    background: '#FDE5E5',
    100: '#FDE5E5',
    200: '#FBC4C7',
    300: '#EC8D90',
    600: '#B00007',
    700: '#820000',
  },
  archived: { color: tannerGray['800'], background: tannerGray['100'] },
};

const tannerDanger = {
  main: '#FDE5E5',
  light: '#D10A12',
};

const tannerWhite = '#FFFFFF';
const tannerBlack = '#000000';

export {
  tannerGray,
  tannerPurple,
  tannerBlue,
  tannerSemantic,
  tannerWhite,
  tannerBlack,
  tannerDanger,
};
